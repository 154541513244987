// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---readme-md": () => import("./../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-architecture-testnet-architecture-mdx": () => import("./../../src/architecture/testnet-architecture.mdx" /* webpackChunkName: "component---src-architecture-testnet-architecture-mdx" */),
  "component---src-connectors-clara-connector-mdx": () => import("./../../src/connectors/clara-connector.mdx" /* webpackChunkName: "component---src-connectors-clara-connector-mdx" */),
  "component---src-connectors-emmett-connector-mdx": () => import("./../../src/connectors/emmett-connector.mdx" /* webpackChunkName: "component---src-connectors-emmett-connector-mdx" */),
  "component---src-connectors-marty-connector-mdx": () => import("./../../src/connectors/marty-connector.mdx" /* webpackChunkName: "component---src-connectors-marty-connector-mdx" */),
  "component---src-ilp-packets-mdx": () => import("./../../src/ilp-packets.mdx" /* webpackChunkName: "component---src-ilp-packets-mdx" */),
  "component---src-index-mdx": () => import("./../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-making-payments-mdx": () => import("./../../src/making-payments.mdx" /* webpackChunkName: "component---src-making-payments-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

